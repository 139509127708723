import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0378edb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "options-container" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "next-value-dropdown-container",
      style: _normalizeStyle($props.hasColor ? 'color:white;background-color:' + $props.modelValue?.color : '')
    }, [
      _createElementVNode("div", {
        class: "value",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.toggleOverlay && $setup.toggleOverlay(...args)))
      }, _toDisplayString($props.modelValue ? $props.modelValue[$props.optionLabel] : "Select option"), 1),
      _createElementVNode("div", {
        class: "next-value-button",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.activateNextOption && $setup.activateNextOption(...args))),
        title: $setup.nextOption ? 'NEXT: ' + $setup.nextOption[$props.optionLabel] : ''
      }, [
        _createVNode(_component_mdicon, {
          name: "play",
          size: 16
        })
      ], 8, _hoisted_1)
    ], 4),
    _createVNode(_component_OverlayPanel, { ref: "overlay" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["option", { active: $props.modelValue[$props.optionValue] === option[$props.optionValue] }]),
              onClick: ($event: any) => ($setup.onOptionClick(option))
            }, [
              _createTextVNode(_toDisplayString(option[$props.optionLabel]) + " ", 1),
              ($props.modelValue[$props.optionValue] === option[$props.optionValue])
                ? (_openBlock(), _createBlock(_component_mdicon, {
                    key: 0,
                    name: "check"
                  }))
                : _createCommentVNode("", true)
            ], 10, _hoisted_3))
          }), 256))
        ])
      ]),
      _: 1
    }, 512)
  ], 64))
}