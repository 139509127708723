
import { computed, reactive, ref, defineComponent, watch } from "vue"
import flatPickr from "vue-flatpickr-component"
import "flatpickr/dist/flatpickr.css"
import SalesService from "@/services/SalesService"
import InputNumber from "primevue/inputnumber"
import OrganizationService from "@/services/OrganizationService"
import { state } from "@/utils/state"
import PrimaryButton from "../buttons/PrimaryButton.vue"
import SecondaryButton from "../buttons/SecondaryButton.vue"
import LinkLabel from "../navigation/LinkLabel.vue"
import NextValueDropdown from "@/components/general/NextValueDropdown.vue"
import { saleStatusColors } from "@/constants/SaleStatusColors"
import SaleAssigneeComponent from "./SaleAssigneeComponent.vue"
import useTimeUtils from "@/utils/time"
import InputSwitch from "primevue/inputswitch"
import Sale from "@/models/Sale"
import LoadingSpinner from "../general/LoadingSpinner.vue"

export default {
  name: "SalesComponent",
  components: {
    flatPickr,
    InputNumber,
    PrimaryButton,
    SecondaryButton,
    LinkLabel,
    NextValueDropdown,
    SaleAssigneeComponent,
    InputSwitch,
    LoadingSpinner,
  },
  props: {
    organizationId: { type: String },
    sale: { type: Sale },
    allStatuses: Array,
  },
  emits: ["close-and-reload", "show-create-quotation-dialog"],
  setup(props: any, { emit }: any) {
    const { absoluteDateTime } = useTimeUtils()
    const saleDialogVisible = ref(true)
    const salesService = new SalesService()
    const regions = reactive([])
    const currencies = reactive([])
    const organizationTypes = reactive([])
    const organizationType = ref()
    const region = ref()
    const currency = ref()
    const status = ref(props.sale?.status ?? null)
    const statuses = reactive([])
    const confirmDeleteDialogVisible = ref(false)
    const orgOptions = reactive([])
    const selectedOrganization = ref("")
    const inputNumberProps = {
      autocomplete: "off",
    }
    const saleSaving = ref(false)

    const margin = computed(() =>
      props.sale.price
        ? props.sale.price - (props.sale.purchasePrice ?? 0) - (props.sale.tradeInPrice ?? 0)
        : 0
    )
    const marginPercent = computed(() =>
      props.sale.price !== null && margin.value !== null
        ? Math.round((margin.value / props.sale.price) * 10000) / 100
        : 0
    )
    const successRateLater = computed(
      () =>
        100 -
        (props.sale.successRateM0 ?? 0) -
        (props.sale.successRateM1 ?? 0) -
        (props.sale.successRateM2 ?? 0)
    )
    const timeout = ref(null)

    const successRateM0MaxValue = computed(
      () => 100 - props.sale.successRateM1 - props.sale.successRateM2
    )
    const successRateM1MaxValue = computed(
      () => 100 - props.sale.successRateM0 - props.sale.successRateM2
    )
    const successRateM2MaxValue = computed(
      () => 100 - props.sale.successRateM0 - props.sale.successRateM1
    )

    getAllOrgs()
    function getAllOrgs() {
      orgOptions.length = 0
      state.allOrganizations.forEach((org) => {
        orgOptions.push(org)
      })
      selectedOrganization.value = orgOptions[0]?.id
    }

    const showSaveAndCreateQuotationButton = computed(() => {
      if (props.sale) {
        if (props.sale.quotations) {
          if (props.sale.quotations.length === 0) {
            //no quotations
            return true
          } else if (props.sale.quotations.length) {
            //has quotations
            return false
          }
        }
      }
      return true
    })

    const flatPickConfig = ref({
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: "Y-m-d",
    })

    Promise.all([
      salesService.getCurrencies(),
      salesService.getOrganizationTypes(),
      salesService.getRegions(),
    ]).then((res: any) => {
      ;[].push.apply(currencies, res[0])
      ;[].push.apply(organizationTypes, res[1])
      ;[].push.apply(regions, res[2])

      currency.value = props.sale?.currency ?? currencies[0]
      //language.value = props.sale?.language ?? languages[0]
      organizationType.value = props.sale?.organizationType ?? organizationTypes[0]
      region.value = props.sale?.region ?? regions[0]
    })
    ;[].push.apply(
      statuses,
      props.allStatuses?.map((status: string) => {
        return {
          name: status.toLowerCase().replace("_", " "),
          value: status,
          color: saleStatusColors.get(status),
        }
      })
    )
    const foundStatus = statuses.find((status: any) => {
      return status.value === props.sale?.status
    })
    status.value = foundStatus || statuses[0]

    watch(
      () => [
        props.sale?.price,
        props.sale?.purchasePrice,
        props.sale?.tradeInPrice,
        props.sale?.successRate,
        props.sale?.successRateM0,
        props.sale?.successRateM1,
        props.sale?.successRateM2,
      ],
      () => {
        save(null, true)
      }
    )

    function save(callback: any, autosave: boolean = false) {
      if (props?.sale?.id) {
        saleSaving.value = true
        clearTimeout(timeout.value)
        //update sale
        timeout.value = setTimeout(() => {
          props.sale.organizationType = organizationType.value
          props.sale.region = region.value
          props.sale.currency = currency.value
          props.sale.status = status.value?.value
          props.sale.margin = margin.value
          props.sale.successRateLater = successRateLater.value
          salesService.updateSale(props.sale.id, props.sale).then((res: any) => {
            saleSaving.value = false
            callback && callback({ saleId: res })
          })
        }, 500)
      } else {
        if (autosave) return
        //create sale
        const newSale = {
          demoDate: props.sale.demoDate,
          billingDate: props.sale.billingDate,
          currency: (props.sale.currency = currency.value),
          interCo: props.sale.interCo,
          price: props.sale.price ?? 0,
          purchasePrice: props.sale.purchasePrice ?? 0,
          margin: props.sale.margin,
          tradeInPrice: props.sale.tradeInPrice ?? 0,
          successRate: props.sale.successRate ?? 0,
          successRateM0: props.sale.successRateM0 ?? 0,
          successRateM1: props.sale.successRateM1 ?? 0,
          successRateM2: props.sale.successRateM2 ?? 0,
          language: props.sale.language,
          organizationType: (props.sale.organizationType = organizationType.value),
          summary: props.sale.summary,
          comment: props.sale.comment,
          status: (props.sale.status = status.value?.value),
          region: (props.sale.region = region.value),
        }
        let orgId = props.organizationId ? props.organizationId : selectedOrganization.value
        salesService.postSale(orgId, newSale).then((res: any) => {
          callback && callback({ saleId: res })
          emit("close-and-reload")
        })
      }
    }

    function showConfirmDeleteDialog() {
      confirmDeleteDialogVisible.value = true
    }

    function confirmDelete() {
      salesService.deleteSale(props.sale.id).then(() => {
        confirmDeleteDialogVisible.value = false
        emit("close-and-reload", true)
      })
    }

    function cancelDelete() {
      confirmDeleteDialogVisible.value = false
    }

    function saveAndCreateQuotation() {
      save(({ saleId }: any) => {
        if (saleId === "") {
          saleId = props.sale?.id
        }
        let orgId = props.organizationId ? props.organizationId : selectedOrganization.value
        emit("show-create-quotation-dialog", { saleId }, orgId)
      })
    }

    function saveAfterStatusChange() {
      if (props.sale.id) {
        save(Function.prototype)
      }
      return
    }

    return {
      flatPickConfig,
      currencies,
      margin,
      marginPercent,
      successRateLater,
      organizationType,
      region,
      currency,
      organizationTypes,
      statuses,
      status,
      save,
      showConfirmDeleteDialog,
      confirmDeleteDialogVisible,
      confirmDelete,
      cancelDelete,
      saveAndCreateQuotation,
      showSaveAndCreateQuotationButton,
      selectedOrganization,
      orgOptions,
      state,
      regions,
      absoluteDateTime,
      saveAfterStatusChange,
      successRateM0MaxValue,
      successRateM1MaxValue,
      successRateM2MaxValue,
      inputNumberProps,
      saleSaving,
    }
  },
}
