
import { computed, ref } from "vue"

export default {
  name: "NextValueDropdown",
  props: {
    modelValue: Object,
    // Prefered options array: [{label: "label", value: "value", (color: "color")}, {}, ...]
    options: Array,
    optionLabel: {
      type: String,
      required: true,
    },
    optionValue: {
      type: String,
      required: true,
    },
    hasColor: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "change"],
  setup(props: any, { emit }: any) {
    const overlay = ref()
    const nextOption = computed(() => {
      const currentOptionIndex = props.options.findIndex(
        (option: any) => props.modelValue[props.optionValue] === option[props.optionValue]
      )
      if (currentOptionIndex === -1 || currentOptionIndex === props.options.length - 1) {
        return props.options[0]
      } else {
        return props.options[currentOptionIndex + 1]
      }
    })

    function toggleOverlay(event: any) {
      overlay.value.toggle(event)
    }

    function onOptionClick(option: any) {
      emit("update:modelValue", option)
      emit("change")
      overlay.value.hide()
    }

    function activateNextOption() {
      emit("update:modelValue", nextOption.value)
      emit("change")
    }

    return {
      overlay,
      toggleOverlay,
      onOptionClick,
      activateNextOption,
      nextOption,
    }
  },
}
