
import ResultSet from "@/models/ResultSet"
import SalesService from "@/services/SalesService"
import { reactive, ref } from "vue"
import UserGroupService from "@/services/UserGroupService"
import UserGroup from "@/models/UserGroup"

export default {
  name: "SaleAssigneeComponent",
  emits: [],
  props: {
    sale: Object,
  },
  setup(props: any, { emit }: any) {
    const salesService = new SalesService()
    const userGroupService = new UserGroupService()
    const employees = reactive(new Map())
    const employeeOverlay = ref()
    const rawUserGroups: any = []
    const userGroups: Array<any> = reactive([])
    const newAssigneeDialog = ref(false)

    loadEmployees()

    async function loadEmployees() {
      const saleCompanyId = props.sale?.company?.id
      if (!saleCompanyId) return
      if (rawUserGroups.length) {
        handleUserGroups()
      } else {
        const groups = await userGroupService.getUserGroups(0, "", "", saleCompanyId, 1000)
        rawUserGroups.push(...groups.rows)
        handleUserGroups()
      }
    }

    function handleUserGroups() {
      userGroups.length = 0
      rawUserGroups.forEach((userGroup: any) => {
        if (userGroup.users.length) {
          userGroup.users
            .sort((a: any, b: any) => {
              const usernameA = a.firstName?.toLowerCase()
              const usernameB = b.firstName?.toLowerCase()
              if (usernameA < usernameB) return -1
              if (usernameA > usernameB) return 1
              return 0
            })
            .forEach((employee: any) => {
              const assigneeMatch = props.sale.assignees.find(
                (assignee: any) => assignee.id === employee.id && !assignee.deleted
              )
              if (assigneeMatch) {
                employee.selected = true
              } else {
                employee.selected = false
              }
              employees.set(employee.id, employee)
            })

          userGroup.selected =
            userGroup.users.map((employee: any) => employee.selected).indexOf(false) === -1

          userGroups.push(userGroup)
        }
      })
      userGroups.sort((a: any, b: any) => {
        const userGroupNameA = a.alias.toLowerCase()
        const userGroupNameB = b.alias.toLowerCase()
        if (userGroupNameA < userGroupNameB) return -1
        if (userGroupNameA > userGroupNameB) return 1
        return 0
      })
    }

    function showDialog() {
      newAssigneeDialog.value = true
    }

    function postAssignee(employee: any) {
      if (props.sale.id) {
        return salesService.addAssignee(props.sale.id, employee.id)
      }
      return Promise.resolve()
    }

    function deleteAssignee(employee: any) {
      return salesService.deleteAssignee(props.sale.id, employee.id)
    }

    function onEmployeeClick(employee: any, selected?: boolean) {
      if (props.sale.assignees.length <= 1 && employee.selected) {
        return
      }
      if ((selected !== null || selected !== undefined) && employee.selected === selected) return
      employee.selected = selected ?? !employee.selected

      if (employee.selected) {
        props.sale.assignees.push(employee)
        postAssignee(employee)
      } else {
        const foundIndex = props.sale.assignees.findIndex(
          (assignee: any) => assignee.id === employee.id
        )
        props.sale.assignees.splice(foundIndex, 1)
        deleteAssignee(employee)
      }
    }

    function onUserGroupClick(userGroup: any) {
      const everyEmployeeSelectedInUserGroup = userGroup.users.every(
        (employee: any) => employees.get(employee.id).selected
      )
      if (
        props.sale.assignees.length <= userGroup.users.length &&
        everyEmployeeSelectedInUserGroup
      ) {
        return
      }

      userGroup.selected = !everyEmployeeSelectedInUserGroup

      userGroup.users.forEach((employee: any) => {
        onEmployeeClick(employees.get(employee.id), userGroup.selected)
      })
    }

    return {
      employeeOverlay,
      employees,
      postAssignee,
      onEmployeeClick,
      newAssigneeDialog,
      onUserGroupClick,
      showDialog,
      userGroups,
    }
  },
}
