import { SalesStatus } from "@/enum/SalesStatus"

export default class Sale {
  assignees: Array<any>
  billingDate: string
  comment: string
  company: any
  createdBy: any
  createdTime: string
  currency: string
  deleted: boolean
  demoDate: string
  id: string
  interCo: boolean
  margin: number
  organization: any
  organizationType: string
  price: number
  purchasePrice: number
  quotations: Array<any>
  region: string
  startDate: string
  status: string
  successRate: number
  successRateM0: number
  successRateM1: number
  successRateM2: number
  summary: string
  taskId: string
  tradeInPrice: number
  updatedTime: string

  constructor(sale: any = {}) {
    Object.assign(this, sale)
  }
}

export const defaultSale: Sale = {
  assignees: [],
  billingDate: null,
  comment: "",
  company: null,
  createdBy: null,
  createdTime: null,
  currency: "EUR",
  deleted: false,
  demoDate: null,
  id: "",
  interCo: false,
  margin: 0,
  organization: null,
  organizationType: "EQ",
  price: 0,
  purchasePrice: 0,
  quotations: [],
  region: "BENELUX",
  startDate: null,
  status: SalesStatus.POTENTIAL.toString(),
  successRate: 0,
  successRateM0: 0,
  successRateM1: 0,
  successRateM2: 0,
  summary: "",
  taskId: null,
  tradeInPrice: 0,
  updatedTime: null,
}
