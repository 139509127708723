// Make it better by storing colors in backend like task labels?
import { SalesStatus } from "@/enum/SalesStatus"

export let saleStatusColors = new Map()
saleStatusColors.set(SalesStatus.POTENTIAL, "#f978c1")
saleStatusColors.set(SalesStatus.FIRST_CONTACT, "#b3743d")
saleStatusColors.set(SalesStatus.INFO_SHARED, "#b3a53d")
saleStatusColors.set(SalesStatus.OFFER, "#3db370")
saleStatusColors.set(SalesStatus.DEMO, "#3db3b3")
saleStatusColors.set(SalesStatus.TRAIL, "#62e7e6")
saleStatusColors.set(SalesStatus.FOLLOW_UP, "#3d64b3")
saleStatusColors.set(SalesStatus.TO_INVOICE, "#643db3")
saleStatusColors.set(SalesStatus.INVOICED, "#ab3db3")
saleStatusColors.set(SalesStatus.PAID, "#3db341")
saleStatusColors.set(SalesStatus.MISSED, "#b33d3d")
saleStatusColors.set(SalesStatus.OLD, "#999999")
