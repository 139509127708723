import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56c87b29"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "assignees-present-container"
}
const _hoisted_2 = { class: "assignee-initial" }
const _hoisted_3 = {
  key: 1,
  class: "no-assignees-container",
  title: "Add an assignee"
}
const _hoisted_4 = { class: "user-groups-select-list" }
const _hoisted_5 = { class: "user-group-item" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "user-group-title-alias" }
const _hoisted_8 = { class: "user-group-employees" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "assignee-initial" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.showDialog && $setup.showDialog(...args))),
      class: "assignee-container"
    }, [
      ($props.sale.assignees.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.sale.assignees.slice(0, 3), (assignee) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(assignee.firstName.charAt(0) + assignee.lastName.charAt(0)), 1))
            }), 256))
          ]))
        : _createCommentVNode("", true),
      (!$props.sale.assignees.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_mdicon, { name: "accountMultiplePlus" })
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Dialog, {
      visible: $setup.newAssigneeDialog,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (($setup.newAssigneeDialog) = $event)),
      modal: true,
      draggable: false,
      header: "Select assignees",
      style: {"max-width":"100%"},
      contentStyle: "padding:0;",
      dismissableMask: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.userGroups, (userGroup) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "user-group-title",
                onClick: ($event: any) => ($setup.onUserGroupClick(userGroup))
              }, [
                _createVNode(_component_mdicon, { name: "accountGroup" }),
                _createElementVNode("p", _hoisted_7, _toDisplayString(userGroup.alias), 1)
              ], 8, _hoisted_6),
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(userGroup.users, (employee) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["user-group-employee", { selected: $setup.employees.get(employee.id).selected }]),
                    onClick: ($event: any) => ($setup.onEmployeeClick($setup.employees.get(employee.id)))
                  }, [
                    _createElementVNode("div", _hoisted_10, _toDisplayString(employee.firstName.charAt(0) + employee.lastName.charAt(0)), 1),
                    _createElementVNode("p", null, _toDisplayString(employee.firstName) + " " + _toDisplayString(employee.lastName), 1),
                    (employee.selected)
                      ? (_openBlock(), _createBlock(_component_mdicon, {
                          key: 0,
                          name: "check"
                        }))
                      : _createCommentVNode("", true)
                  ], 10, _hoisted_9))
                }), 256))
              ])
            ]))
          }), 256))
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}